export const messageConstants = {
SET_MESSAGE_REQUEST: 'SET_MESSAGE_REQUEST',
SET_MESSAGE_SUCCESS: 'SET_MESSAGE_SUCCESS',
SET_MESSAGE_FAILURE: 'SET_MESSAGE_FAILURE',

GET_MESSAGES_REQUEST: 'GET_MESSAGES_REQUEST',
GET_MESSAGES_SUCCESS: 'GET_MESSAGES_SUCCESS',
GET_MESSAGES_FAILURE: 'GET_MESSAGES_FAILURE',

GET_ALL_MESSAGES_REQUEST: 'GET_ALL_MESSAGES_REQUEST',
GET_ALL_MESSAGES_SUCCESS: 'GET_ALL_MESSAGES_SUCCESS',
GET_ALL_MESSAGES_FAILURE: 'GET_ALL_MESSAGES_FAILURE',

MARK_MESSAGE_AS_READ_REQUEST: 'MARK_MESSAGE_AS_READ_REQUEST',
MARK_MESSAGE_AS_READ_SUCCESS: 'MARK_MESSAGE_AS_READ_SUCCESS',
MARK_MESSAGE_AS_READ_FAILURE: 'MARK_MESSAGE_AS_READ_FAILURE',

}
