import React from "react";
import {
  CardBody,

  Row,
  Col,
  ModalBody,
} from "reactstrap";
// import { history } from '_helpers';

// import Datetime from "react-datetime";
import { connect } from 'react-redux';
// import { Redirect } from "react-router-dom";
// import * as moment from 'moment';
// import {
//   withScriptjs,
//   withGoogleMap,
//   GoogleMap,
//   Marker
// } from "react-google-maps";

import { feeActions } from '_actions';
          

class FindCelebsHelpModal extends React.Component {
  constructor(props) {
    super(props);
    console.log("CreateUpdateLocation constructor()", props)
    this.state = {
    };
  }

  

  componentDidMount() {
    this.props.dispatch(feeActions.getFees())
  }


  render() {
    const {fees } = this.props   
    const {liveNyteBookingPercentage, liveNyteBookingHandlingFee, stripeFlatFee, stripePercentage, fetchingFees} = fees
    const combinedPercentage = liveNyteBookingPercentage + stripePercentage
    const combinedFlatFee = liveNyteBookingHandlingFee + stripeFlatFee
    console.log("fees", fees)
    // let remit = num * ticket.price
    // let lvFee = remit * (liveNyteTicketPercentage/100) +
    //             num   * liveNyteTicketFlatFee
    // let stripeFee = (remit + lvFee + stripeFlatFee) / (1 - (stripePercentage/100)) - remit - lvFee
    // console.log("num", num, "remit", remit, "lvFee", lvFee, "stripeFee", stripeFee)
    console.log("BiddingHelpModal: render", this.state)


    if (fetchingFees === true) {
      return(
        <div>Loading</div>
      )
    }
    return (
      <ModalBody>

          <Row>
            <Col xs={12}>
              <CardBody className="text-left">
              <h4>How Bidding Works</h4>
                By submitting a bid or a bid request, you authorize LiveNyte to competitively place your bid against others for a talent's services, pending the talent's approval of your bid.
                You also authorize the talent to use the <b>'Book Early'</b> option, allowing them to immediately accept your bid at their hourly rate.
                If you win, you authorize LiveNyte to charge your payment method—never exceeding your maximum bid amount—for the winning price. If the transaction is successful, you've officially booked that talent for your event. The winning price will be set at $1 more than the second-highest bid.
                <br/><br/>
                LiveNyte will never overbook talent. You're free to place as many bids as you'd like, and you'll only win up to the maximum number of celebrities you've specified.
                <br/><br/>
                <h4>Book Now</h4>
                By submitting a <i>'Book Now'</i> request, you authorize LiveNyte to immediately charge your payment method for booking talent during your selected time block at the <i>'Book Now'</i>  price.
                If the charge is successful, you have successfully booked that talent for your event.
                <br/><br/>
                <h4>Quick Tips</h4>
                • Some talent require manual approval for each bid. If a talent you want to book has this setting enabled, you won't be able to use Book Now until they approve your bid request.
                <br/><br/>
                • Please submit bids in whole USD amounts without using decimal points or the '$' symbol.
                <br/><br/>
                <h4>Fees</h4>
                LiveNyte lists talent services for {combinedPercentage}% + ${combinedFlatFee} more. From this, LiveNyte collects {liveNyteBookingPercentage}% + ${liveNyteBookingHandlingFee} as commission and pays the payment processor {stripePercentage}% + ${stripeFlatFee}.
                <br/><br/>
                <h4>Grievances</h4>
                If you have a significant grievance regarding a booking (e.g., talent was substantially late, no-show, etc.), you have up to four days after the event to file a case with LiveNyte. 
                To do this, go to the event messenger, tap the talent you wish to file a case against, then click the Help button in the text conversation between you and the talent, and select <b>'Freeze Payment'. </b>
                LiveNyte will then assist you and the talent in reaching a resolution.
                <br/><br/>
                Abuse of this feature may result in account suspension or termination.
                <br/><br/>
                Note: We highly recommend that you review the talent's reviews, written by other businesses who have booked that talent.
                <br/><br/>
                <i>LiveNyte reserves the right to change its fees in the future.</i>
              </CardBody>

              </Col>
          </Row>
        </ModalBody> 
    );
  }
}

function mapStateToProps(state) {
  const {  feesData } = state;
  const { fees, fetchingFees } = feesData;
  return {
    fees,fetchingFees
  };
}

const connectedFindCelebsHelpModal = connect(mapStateToProps)(FindCelebsHelpModal);
export { connectedFindCelebsHelpModal as FindCelebsHelpModal }; 