import React, { Suspense, lazy } from 'react';
// import { hydrate, render } from "react-dom";
import ReactDOM from "react-dom"
// react library for routing
import { 
  // BrowserRouter, 
  Router, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from 'react-redux';

// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
// plugins styles downloaded
import "assets/vendor/fullcalendar/dist/fullcalendar.min.css";
import "assets/vendor/sweetalert2/dist/sweetalert2.min.css";
import "assets/vendor/select2/dist/css/select2.min.css";
import "assets/vendor/quill/dist/quill.core.css";
import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/@fortawesome/fontawesome-free/css/all.css";

// core styles
// import "assets/scss/argon-dashboard-pro-react.scss?v1.0.0";

// import DashLayout from "layouts/Dash.jsx";
import AuthLayout from "layouts/Auth.jsx";
import ViewFallback from "components/Fallbacks/ViewFallback.jsx"

// import PublicLayout from "layouts/Public.jsx";
// import RedirectLayout from "layouts/RedirectLayout.jsx";
// import MobilePageLayout from "layouts/MobilePage.jsx";

// import PrunedAudioPlayer from "components/AudioPlayer/PrunedAudioPlayer.js"
import liveNyteSquare from "assets/img/img-livenyte/livenyte-square-icon.jpg"
import { IndexView } from "views/Index.jsx";
import {Helmet} from "react-helmet";
// import ReactPixel from 'react-facebook-pixel';

import { store, history, saveState, 
  // withTracker 
} from '_helpers';

store.subscribe(() => {
  saveState(store.getState());
});

if (process.env.NODE_ENV !== 'development') {
  console.log = () => {}
}

const DashLayout = lazy(() => import("layouts/Dash.jsx"));
// const AuthLayout = lazy(() => import("layouts/Auth.jsx"));
const PublicLayout = lazy(() => import("layouts/Public.jsx"));
const RedirectLayout = lazy(() => import("layouts/RedirectLayout.jsx"));
const MobilePageLayout = lazy(() => import("layouts/MobilePage.jsx"));

// const advancedMatching = { em: 'some@email.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const advancedMatching = { }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

// ReactPixel.init('792699707758108', advancedMatching, options);
// ReactPixel.revokeConsent();


// const app =  
// (<Provider store={store}>
//   <Router history={history}>
//     <Helmet>
//     <title>LiveNyte: A place where creators can do more. </title>
//     <meta name="description" content="Create your own website. Sell digital content and subscriptions. Manage your calendar. Get booked at live events. "/>
//     <meta property="og:type" content="article" />
//     <meta property="og:title" content="LiveNyte: A place where creators can do more." />
//     <meta property="og:description" content="Create your own website. Sell digital content and subscriptions. Manage your calendar. Get booked at live events." />
//     <meta property="og:image" content={liveNyteIcon}/>
//     <meta property="og:url" content="https://livenyte.com" />
//     <meta property="og:site_name" content="LiveNyte" />
//     <meta name="twitter:title" content="LiveNyte: A place where creators can do more."/>
//     <meta name="twitter:description" content="Create your own website. Sell digital content and subscriptions. Manage your calendar. Get booked at live events. "/>
//     <meta name="twitter:image" content={liveNyteIcon}/>
//     </Helmet>
//     <Switch>
//       <Route path="/dash" render={props => <DashLayout {...props} />} />
//       <Route path="/auth" render={props => <AuthLayout {...props} />} />
//       <Route path="/public" render={props => <PublicLayout {...props} />} />
//       <Route path="/redirect" render={props => <RedirectLayout {...props} />} />
//       <Route path="/mobile" render={props => <RedirectLayout {...props} />} />
//       <Route path="/" render={props => <IndexView {...props} />} />
//       <Redirect from="*" to="/" />
//     </Switch>
//   </Router>
// </Provider>)

// const rootElement = document.getElementById("root");
// if (rootElement.hasChildNodes()) {
//   hydrate(app, rootElement);
// } else {
//   render(app, rootElement);
// }

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
        <Helmet>
          <title>LiveNyte: Your time is now.</title>
          <meta name="description" content="Create your own website. Sell digital content and subscriptions. Go on tour. Get booked at live events."/>
          <meta property="og:type" content="article" />
          <meta property="og:title" content="LiveNyte: A place where creators can do more." />
          <meta property="og:description" content="Create your own website. Sell digital content and subscriptions. Go on tour. Get booked at live events." />
          <meta property="og:image" content={liveNyteSquare}/>
          <meta property="og:url" content="https://livenyte.com" />
          <meta property="og:site_name" content="LiveNyte" />
          <meta name="twitter:title" content="LiveNyte: A place where creators can do more."/>
          <meta name="twitter:description" content="Create your own website. Sell digital content and subscriptions. Go on tour. Get booked at live events."/>
          <meta name="twitter:image" content={liveNyteSquare}/>
        </Helmet>
      <Switch>
      <Route path="/dash" render={props => 
          <Suspense fallback={<ViewFallback/>}>
            <DashLayout {...props} />       
          </Suspense>
          }
      />
        <Route path="/auth" render={props => 
          <Suspense fallback={<ViewFallback/>}>
            <AuthLayout {...props} />       
          </Suspense>}
         />
        <Route path="/public" render={props => 
          <Suspense fallback={<ViewFallback/>}>
            <PublicLayout {...props} />    
          </Suspense>}
       />
        <Route path="/redirect" render={props => 
          <Suspense fallback={<ViewFallback/>}>
            <RedirectLayout {...props} />    
          </Suspense>}
        />
        <Route path="/mobilePage" render={props => 
          <Suspense fallback={<ViewFallback/>}>
            <MobilePageLayout {...props} />    
          </Suspense>}
        />
        <Route path="/mobile" render={props => 
         <Suspense fallback={<ViewFallback/>}>
          <RedirectLayout {...props} />    
        </Suspense>}
        />
        <Route path="/" render={props => 
          <IndexView {...props} />    
          }
        />
        <Route path="*" render={() => <Redirect to="/" />} />
      </Switch>
      {/* <PrunedAudioPlayer/> */}
    </Router>
  </Provider>,
  document.getElementById("root")
);

