import { createTheme, } from '@mui/material/styles';

export const muiTheme = createTheme({
  palette: {
    primary: {
      main: '#FF4F83', // Primary color
    },
    secondary: {
      main: '#00BCD4', // Secondary color (optional)
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          display: 'flex',
          paddingRight: '3em',
          '& .MuiOutlinedInput-root': {
            borderRadius: '0.25rem',
            '& fieldset': {
              borderColor: '#dee2e6', // Default border color
              borderRadius: '0.25rem',
            },
            '&:hover fieldset': {
              borderColor: '#dee2e6', // Border color on hover
            },
            '&.Mui-focused fieldset': {
              borderColor: '#FF4F83', // Border color when focused
              borderRadius: '0.25rem',
            },
            '& input': {
              color: '#8898aa', // Change the text color inside the input
              fontSize: '0.875rem', // Change input text size
            },
          },
        },
      },
    },
    MuiInputLabel: {
        styleOverrides: {
          root: {
            color: '#8898aa', // Label color
            fontFamily: '"Open Sans", sans-serif',
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            color: '#FFA500', // Helper/error text color
            fontFamily: '"Open Sans", sans-serif',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '&.Mui-error fieldset': {
              borderColor: '#FFA500', // Error border color
            },
            fontFamily: '"Open Sans", sans-serif',
          },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          root: {
            color: '#525f7f', // Unselected day text color
            '&.Mui-selected': {
              backgroundColor: '#FF4F83', // Selected day background
              color: '#ffffff', // Selected day text color
            },
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            color: '#525f7f', // Apply to month and year text
          },
        },
      },
      MuiPickersYear: {
        styleOverrides: {
          root: {
            color: '#525f7f', // Apply to year selection
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            // backgroundColor: '#ffffff', // Background color for popups
            color: '#525f7f', // Default text color
          },
        },
      },
      MuiPickersClockNumber: {
        styleOverrides: {
          root: {
            // color: '#525f7f', // Change text color for hour/minute picker
            // '&.Mui-selected': {
            //   color: '#ffffff', // Selected hour/minute text color
            //   backgroundColor: '#FF4F83', // Selected background color
            // },
          },
        },
      },
  },
});