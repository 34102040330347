import React from "react";
import {
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col,
    CardImg,
    UncontrolledTooltip
  } from "reactstrap";
  // react plugin used to 
// import { history } from '_helpers';
import {  dispTimePeriod} from '_helpers';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FaExclamation } from "react-icons/fa";

import * as moment from 'moment';

import noImage from "assets/img/img-livenyte/LiveNyteNoImage.jpg";

class CelebEventCard  extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };

  }

  componentDidMount() {

  }

  render() {
    const now = moment() 
    const event = this.props.event
    const eventsNeedingReviews = this.props.eventsNeedingReviews
    const newMessageEvents = this.props.newMessageEvents
    return (
        <Link to={ {pathname: `/dash/celeb-event/`, 
                        state: { eventId: event.event_id, businessId: event.business_id, bidId: event.bid_id, schId: event.sch_id} 
                    }}
                                  // activeClassName="active"
                                >
                      <Card>
                        <CardImg
                          alt="..."
                          src={event.image_url}
                          onError={(e)=>{e.target.onerror = null; e.target.src=noImage}}
                          top
                        />
                        <CardBody>
                          <CardTitle className="h2 mb-0">
                          {event.event_name}
                          </CardTitle>
                          <h6 className="surtitle pt-2">
                            {dispTimePeriod(event.sb_start_time, event.sb_end_time, event.time_zone, true)}                                              
                          </h6>
                        </CardBody>
                        <CardFooter>
                          <Row>
                            <Col md={6} className="ml-auto pt-1" > 
                              <h4> {event.sp_trait} </h4>
                            </Col> 
                            <Col md={6} className="text-right">
                              <Row className="justify-content-end">
                              { (newMessageEvents.length > 0 && newMessageEvents.filter(ev => ev.event_id === event.event_id).length > 0) && 
                                <Col className="col-auto pl-0">
                                  <div 
                                    id={`event_${event.event_id}_bid_id${event.bid_id}_message`}
                                    className="icon icon-sm icon-shape bg-gold text-white rounded-circle shadow">
                                    <i className="ni ni-chat-round" />
                                    <UncontrolledTooltip
                                    delay={0}
                                    target={`event_${event.event_id}_bid_id${event.bid_id}_message`}
                                    >
                                    New Message
                                  </UncontrolledTooltip>  
                                  </div>
                                </Col>}
                                {eventsNeedingReviews.filter ( e => e.event_id === event.event_id).length > 0 &&
                                <Col className="col-auto pl-0">
                                  <div 
                                  id={`event_${event.event_id}_bid_id${event.bid_id}_needs_review`}
                                  className="icon icon-sm icon-shape bg-gradient-pumpkin text-white rounded-circle shadow">
                                    <i className="fas fa-pen" />
                                    <UncontrolledTooltip
                                    delay={0}
                                    target={`event_${event.event_id}_bid_id${event.bid_id}_needs_review`}
                                    >
                                    Review Available
                                  </UncontrolledTooltip> 
                                  </div>
                                  
                                </Col>}
                                { moment(event.sb_end_time).isAfter(now) && event.state === "Booked" && 
                                <Col className="col-auto pl-0">
                                  <div
                                  id={`event_${event.event_id}_bid_id${event.bid_id}_booked`}
                                  className="icon icon-sm icon-shape bg-gradient-livenyte text-white rounded-circle shadow">
                                    <i className="fas fa-star" />
                                    <UncontrolledTooltip
                                    delay={0}
                                    target={`event_${event.event_id}_bid_id${event.bid_id}_booked`}
                                    >
                                    Booking
                                  </UncontrolledTooltip>  
                                  </div>

                                </Col>}
                                { moment(event.ev_end_time).isBefore(now) && event.state === "Booked" && 
                                <Col className="col-auto pl-0">
                                    <div 
                                    id={`event_${event.event_id}_bid_id${event.bid_id}_past_booked`}
                                    className="icon icon-sm icon-shape bg-gradient-gray text-white rounded-circle shadow"
                                    >
                                      <i className="fas fa-star" />
                                    <UncontrolledTooltip
                                    delay={0}
                                    target={`event_${event.event_id}_bid_id${event.bid_id}_past_booked`}
                                    >
                                    Past Booking
                                  </UncontrolledTooltip>  
                                  </div>
                                </Col>}
                                {moment(event.sb_start_time).isAfter(now) && event.state === "Request" && 
                                  <Col className="col-auto pl-0">
                                    <div 
                                      id={`event_${event.event_id}_bid_id${event.bid_id}_request`}
                                    className="icon icon-sm icon-shape bg-gradient-purple text-white rounded-circle shadow">
                                    <FaExclamation style={{width: "0.875rem", height:"0.925rem"}}  />
                                    <UncontrolledTooltip
                                    delay={0}
                                    target={`event_${event.event_id}_bid_id${event.bid_id}_request`}
                                    >
                                    Bid Request
                                  </UncontrolledTooltip>  
                                    </div>
                                  </Col>}
                                  { moment(event.sb_start_time).isAfter(now) && event.state === "Bid" && 
                                <Col className="col-auto pl-0">
                                    <div 
                                      id={`event_${event.event_id}_bid_id${event.bid_id}_bid`}
                                    className="icon icon-sm icon-shape bg-gradient-cyan text-white rounded-circle shadow">
                                      <i className="fas fa-bolt" />
                                      <UncontrolledTooltip
                                      delay={0}
                                      target={`event_${event.event_id}_bid_id${event.bid_id}_bid`}
                                      >
                                      Bid
                                    </UncontrolledTooltip> 
                                    </div>
                                </Col>}
                              </Row>
                            </Col>
                          </Row>
                        </CardFooter>
                      </Card>
                    </Link>
    );
  }
}

function mapStateToProps(state) {
  
    console.log("BusinessEventCard: mapStateToProps");
    return {

    };
  }


const connectedCelebEventCard = connect(mapStateToProps)(CelebEventCard);
export { connectedCelebEventCard as CelebEventCard   }; 