
import React, { Suspense, lazy } from 'react';
// react library for routing
import { Route, Switch } from "react-router-dom";
import { connect } from 'react-redux';
import AuthFallback from "components/Fallbacks/AuthFallback.jsx"

// core components
import { IndexNavbar } from "components/Navbars/IndexNavbar.jsx";
import AuthFooter from "components/Footers/AuthFooter.jsx";
import "assets/scss/argon-dashboard-pro-react.scss?v1.0.0";
// import AuthNavbar from "components/Navbars/AuthNavbar.jsx";
import NotificationAlert from "components/react-notification-alert/NotificationAlert";
import { notificationMessageActions, screenSizeActions } from "_actions"
import routes from "routes.js";
import CookieConsent, { Cookies, getCookieConsentValue, resetCookieConsentValue } from "react-cookie-consent";
// import CookieManager from "components/CookieManager/CookieManager.jsx"

class AuthLayout extends React.Component {
  constructor(props) {
    super(props);
    const screenSize = function (is_xxs, is_xs, is_sm, is_md, is_lg, is_xl, is_xxl, updateScreenSizeRequest) {
      console.log("testB", is_xxs, is_xs, is_sm, is_md, is_lg, is_xl, is_xxl, updateScreenSizeRequest, window.innerWidth, updateScreenSizeRequest )
      console.log()
      if (window.innerWidth < 430 && !updateScreenSizeRequest && !(is_xxs && is_xs && is_sm && is_md && is_lg && is_xl && is_xxl) ) { //phones /Does it initialize as XXS? 
        props.dispatch(screenSizeActions.setScreenSizeXXS())
        return
      }
      else if (window.innerWidth >= 430 && window.innerWidth < 577 && !updateScreenSizeRequest && !(!is_xxs && is_xs && is_sm && is_md && is_lg && is_xl && is_xxl)) { //Does it initialize as XS? 
        props.dispatch(screenSizeActions.setScreenSizeXS())
        return
      } else if (window.innerWidth >= 577 && window.innerWidth < 769 && !updateScreenSizeRequest && !(!is_xxs && !is_xs && is_sm && is_md && is_lg && is_xl && is_xxl)) { //Does it initialize as SM? 
        props.dispatch(screenSizeActions.setScreenSizeSM())
        return
      } else if (window.innerWidth >= 769 && window.innerWidth < 993 && !updateScreenSizeRequest && !(!is_xxs && !is_xs && !is_sm && is_md && is_lg && is_xl && is_xxl)) { //Does it initialize as MD? 
        props.dispatch(screenSizeActions.setScreenSizeMD())
        return
      } else if (window.innerWidth >= 993 && window.innerWidth < 1201 && !updateScreenSizeRequest && !(!is_xxs && !is_xs && !is_sm && !is_md && is_lg && is_xl && is_xxl)) {
        props.dispatch(screenSizeActions.setScreenSizeLG())
        return
      } else if (window.innerWidth >= 1201 && window.innerWidth < 1400 && !updateScreenSizeRequest && !(!is_xxs && !is_xs && !is_sm && !is_md && !is_lg && is_xl && is_xxl)) {
        props.dispatch(screenSizeActions.setScreenSizeXL())
        return
      } else if (window.innerWidth >= 1400 && !updateScreenSizeRequest && !(!is_xxs && !is_xs && !is_sm && !is_md && !is_lg && !is_xl && is_xxl)) {
        props.dispatch(screenSizeActions.setScreenSizeXXL())
        return
      }
    }
    this.state = {
      notificationMessage: [], // [ { message: '', color: ''}]
      screenSize: screenSize

    };
    this.handleScreenSize = this.handleScreenSize.bind(this)

  }

  componentDidMount() {
    this.setPropsScreenSize()
    document.body.classList.add('bg-white');
    window.addEventListener('resize', this.handleScreenSize);  

  }

  componentWillUnmount() {
    document.body.classList.remove("bg-white");
    window.removeEventListener('resize', this.handleScreenSize);  

  }

  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainContent.scrollTop = 0;
    }
  }

  handleScreenSize() {
    const { screenSize } = this.state 
    const { is_xxs, is_xs, is_sm, is_md, is_lg, is_xl, is_xxl, updateScreenSizeRequest } = this.props
    console.log("testA", is_xxs, is_xs, is_sm, is_md, is_lg, is_xl, is_xxl, updateScreenSizeRequest )
    screenSize(is_xxs, is_xs, is_sm, is_md, is_lg, is_xl, is_xxl, updateScreenSizeRequest)
  }

  setPropsScreenSize() {
    if (window.innerWidth < 430) { //phones /Does it initialize as XXS? 
      this.props.dispatch(screenSizeActions.setScreenSizeXXS())
    }
    else if (window.innerWidth < 577) { //Does it initialize as XS? 
      this.props.dispatch(screenSizeActions.setScreenSizeXS())
    
    } else if (window.innerWidth < 769) { //Does it initialize as SM? 
      this.props.dispatch(screenSizeActions.setScreenSizeSM())
    
    } else if (window.innerWidth < 993) { //Does it initialize as MD? 
      this.props.dispatch(screenSizeActions.setScreenSizeMD())
    
    } else if (window.innerWidth < 1201) {
      this.props.dispatch(screenSizeActions.setScreenSizeLG())
    
    } else if (window.innerWidth < 1400) {
      this.props.dispatch(screenSizeActions.setScreenSizeXL())
    
    } else if (window.innerWidth >= 1400) {
      this.props.dispatch(screenSizeActions.setScreenSizeXXL())
    
    }

  }

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          // <Suspense fallback={<AuthFallback/>}>
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          // </Suspense>
        );
      } else {
        return null;
      }
    });
  };

  notify() {
    const {notificationMessage} = this.state
    const {newNotification, newNotificationRequest} = this.props

    if (newNotificationRequest) {
      this.props.dispatch(notificationMessageActions.clearNotification())
      this.setState({notificationMessage: [...this.state.notificationMessage, newNotification]})
      return
    }

    console.log("New Notification", newNotification, newNotificationRequest)
    if (notificationMessage.length === 0) {
      return;
    }
    let type;
    switch (notificationMessage[0].color) {
      case 1:
        type = "success";
        break;
      case 2:
        type = "danger";
        break;
      case 3:
        type = "info";
        break;
      default:
        break;
    }
    let options = {};
    options = {
      place: "tr",
      message: (
        <div>
          <div>
            {notificationMessage[0].message}
          </div>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7
    };
    console.log("refs", this.refs)
    if (this.refs.hasOwnProperty('notificationAlert')) {
      let nm = [...notificationMessage]     
      nm.splice(0, 1)
      this.setState({notificationMessage: nm})
      this.refs.notificationAlert.notificationAlert(options);
    } 
  }

  render() {
    const { newNotification } = this.props
    console.log("newNotificaiton From Auth", newNotification)
    {console.log("cookieValue", getCookieConsentValue())}

    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        {this.notify()}
        <div className="main-content" ref="mainContent">
        <IndexNavbar linkTextColorDark={false} />
          <Switch>
            <Suspense fallback={<AuthFallback/>}>
              <div className={this.props.is_md ? "" : ""}>
                {this.getRoutes(routes)}
              </div>
          </Suspense>
          </Switch>
        </div>
        <AuthFooter />
        {/* <CookieManager/> */}
      </>
    );
  }
}

function mapStateToProps(state) {
  const {  notificationMessageData, screenSizeData } = state;
  const { newNotification, newNotificationRequest } = notificationMessageData;
  const { is_xxs, is_xs, is_sm, is_md, is_lg, is_xl, is_xxl, updateScreenSizeRequest  } = screenSizeData;

  return {
    newNotification, newNotificationRequest,
    is_xxs, is_xs, is_sm, is_md, is_lg, is_xl, is_xxl, updateScreenSizeRequest

  };
}

const connectedAuthLayout= connect(mapStateToProps)(AuthLayout);
export default connectedAuthLayout