import React from "react";
import {
  CardBody,
  Row,
  Col,
  ModalBody,
} from "reactstrap";
// import { history } from '_helpers';

// import Datetime from "react-datetime";
import { connect } from 'react-redux';
// import { Redirect } from "react-router-dom";
// import * as moment from 'moment';
// import {
//   withScriptjs,
//   withGoogleMap,
//   GoogleMap,
//   Marker
// } from "react-google-maps";

          

class BusinessPaymentsHelpModal extends React.Component {
  constructor(props) {
    super(props);
    console.log("CreateUpdateLocation constructor()", props)
    this.state = {
    };
  }

  

  componentDidMount() {

  }

  render() {
    const {fees } = this.props   
    console.log("fees", fees)
    // let remit = num * ticket.price
    // let lvFee = remit * (liveNyteTicketPercentage/100) +
    //             num   * liveNyteTicketFlatFee
    // let stripeFee = (remit + lvFee + stripeFlatFee) / (1 - (stripePercentage/100)) - remit - lvFee
    // console.log("num", num, "remit", remit, "lvFee", lvFee, "stripeFee", stripeFee)
    console.log("BusinessPaymentsHelpModal: render", this.state)

    return (
      <ModalBody>
          <Row>
            <Col xs={12}>
              <CardBody className="text-left">
                <h4>Payout Account</h4>
                Connect a bank account to receive ticket revenue when fans purchase tickets to your events. Your payout details will be securely stored by Stripe. To create events and sell tickets, you'll need to set up your Payout Account.
                <br/><br/>
                <h4>Payout Schedule</h4>
                Ticket sale revenue is usually scheduled to deposit into your connected bank account 3 days after your event.
                <br/><br/>
                PLEASE NOTE: Your first payout may take a few extra days due to additional security and verification steps.
                <br/><br/>
                <h4>Connected Payment Methods</h4>
                Add credit and/or debit cards to cover celebrity booking fees. When schedules close, your primary card will be charged if you've won the booking. If your primary card doesn't have enough funds, we'll automatically try your other cards. If none of your cards can cover the fee, your bid will fail.
                <br/><br/>
                For <b>'Book Now'</b> or <b>'Book Early'</b> bookings, your card is charged immediately. If the charge fails due to insufficient funds, your bid will stay active—it won't be deleted. This gives you a chance to fix the issue and update your payment method.
                <br/><br/>
                <i>LiveNyte reserves the right to change its fees in the future. </i>
              </CardBody>
    
              </Col>
          </Row>
        </ModalBody> 
    );
  }
}

function mapStateToProps(state) {
  const {  feesData } = state;
  const { fees, fetchingFees } = feesData;
  return {
    fees,fetchingFees
  };
}

const connectedBusinessPaymentsHelpModal = connect(mapStateToProps)(BusinessPaymentsHelpModal);
export { connectedBusinessPaymentsHelpModal as BusinessPaymentsHelpModal }; 