import React from "react";
import {
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col,
    CardImg,
    UncontrolledTooltip
  } from "reactstrap";
  // react plugin used to 
// import { history } from '_helpers';
import {  dispTimePeriod} from '_helpers';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as moment from 'moment';

import noImage from "assets/img/img-livenyte/LiveNyteNoImage.jpg";
import { FaQuestion } from "react-icons/fa";

import { eventHasNewMessages } from '_helpers';
 
class SchRequestCard  extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };

  }

  componentDidMount() {

  }

  render() {
    const { allMessages } = this.props 
    const now = moment() 
    const schReq = this.props.schReq
    console.log("Inside SchRequestCard", allMessages[schReq.event_id], schReq.event_id)
    const areNewMessages =  schReq && allMessages && eventHasNewMessages(allMessages, schReq.event_id)

    return (
<Link to={ {pathname: `/dash/sch-request/${schReq.event_id}/${schReq.req_id}`, 
                                        state: { eventId: schReq.event_id, requestId: schReq.req_id} 
                                      }}
                              >
        <Card>
        <CardImg
            alt="..."
            src={schReq.image_url}
            onError={(e)=>{e.target.onerror = null; e.target.src=noImage}}
            top
        />
        <CardBody>
            <CardTitle className="h2 mb-0">
            {schReq.ev_name}
            </CardTitle>
            <h6 className="surtitle pt-2">
            {dispTimePeriod(schReq.start_time, schReq.end_time, schReq.time_zone)}                                              
            </h6>
        </CardBody>
            <CardFooter>
            <Row>
                <Col md={7} className="ml-auto pt-1" > 
                <h4> {schReq.bus_name} </h4>
                </Col> 
                <Col md={5} className="text-right">
                <Row className="justify-content-end">
                { areNewMessages && 
                <Col className="col-auto pl-0">
                    <div 
                        id={`schReq_${schReq.req_id}_event_id${schReq.event_id}_message`}
                        className="icon icon-sm icon-shape bg-gold text-white rounded-circle shadow">
                    <i className="ni ni-chat-round" />
                        <UncontrolledTooltip
                        delay={0}
                        target={`schReq_${schReq.req_id}_event_id${schReq.event_id}_message`}
                        >
                        New Message
                        </UncontrolledTooltip>  
                    </div>
                    </Col>}
                    { moment(schReq.start_time).isAfter(now) && schReq.state === "CREATED" && 
                    <Col className="col-auto pl-0">
                    <div 
                        id={`schReq_${schReq.req_id}_event_id${schReq.event_id}_created`}
                        className="icon icon-sm icon-shape bg-gradient-success text-white rounded-circle shadow" 
                        style={{paddingLeft:"101x", paddingRight:"11px" }}>
                        <i className="fas fa-check" />
                        <UncontrolledTooltip
                            delay={0}
                            target={`schReq_${schReq.req_id}_event_id${schReq.event_id}_created`}
                            >
                            Created Schedule
                        </UncontrolledTooltip> 
                    </div>
                    </Col>}
                    { moment(schReq.start_time).isAfter(now) && schReq.state === "BUSY" && 
                    <Col className="col-auto pl-0">
                    <div 
                        id={`schReq_${schReq.req_id}_event_id${schReq.event_id}_busy`}
                        className="icon icon-sm icon-shape bg-gradient-danger text-white rounded-circle shadow" 
                        style={{paddingLeft:"13px"}} >
                        <i className="fas fa-thumbs-down" />
                        <UncontrolledTooltip
                            delay={0}
                            target={`schReq_${schReq.req_id}_event_id${schReq.event_id}_busy`}
                            >
                            Decline Inquiry
                        </UncontrolledTooltip> 
                    </div>
                    </Col>}
                    { moment(schReq.start_time).isAfter(now) && schReq.state === "PENDING" && 
                    <Col className="col-auto pl-0">
                        <div 
                            id={`schReq_${schReq.req_id}_event_id${schReq.event_id}_inquiry`}
                            className="icon icon-sm icon-shape bg-gradient-warning text-white rounded-circle shadow" 
                            style={{paddingLeft:"10px", paddingRight:"10px" }}>
                        <FaQuestion />
                        <UncontrolledTooltip
                            delay={0}
                            target={`schReq_${schReq.req_id}_event_id${schReq.event_id}_inquiry`}
                            >
                            New Inquiry
                        </UncontrolledTooltip> 
                        </div>
                    </Col>}
                </Row>
                </Col>
            </Row>
            </CardFooter>
        </Card>
        </Link>
    );
  }
}

function mapStateToProps(state) {
    const { messagesData, } = state;

    const { allMessages, fetchingAllMessages } = messagesData;

    console.log("BusinessEventCard: mapStateToProps");
    return {
        allMessages, fetchingAllMessages,

    };
  }


const connectedSchRequestCard = connect(mapStateToProps)(SchRequestCard);
export { connectedSchRequestCard as SchRequestCard   }; 