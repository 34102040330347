import React from "react";
import { Link } from 'react-router-dom';
import {
  CardBody,
  Row,
  Col,
  ModalBody,
} from "reactstrap";
// import { history } from '_helpers';

// import Datetime from "react-datetime";
import { connect } from 'react-redux';
// import { Redirect } from "react-router-dom";
// import * as moment from 'moment';
// import {
//   withScriptjs,
//   withGoogleMap,
//   GoogleMap,
//   Marker
// } from "react-google-maps";

          

class SchedulingHelpModal extends React.Component {
  constructor(props) {
    super(props);
    console.log("CreateUpdateLocation constructor()", props)
    this.state = {
    };
  }

  

  componentDidMount() {

  }


  render() {
    const {fees } = this.props   
    console.log("fees", fees)
    // let remit = num * ticket.price
    // let lvFee = remit * (liveNyteTicketPercentage/100) +
    //             num   * liveNyteTicketFlatFee
    // let stripeFee = (remit + lvFee + stripeFlatFee) / (1 - (stripePercentage/100)) - remit - lvFee
    // console.log("num", num, "remit", remit, "lvFee", lvFee, "stripeFee", stripeFee)
    console.log("SchedulingHelpModal: render", this.state)

    return (
      <ModalBody>
          <Row>
            <Col xs={12}>
                  <CardBody className="text-left">
                    <h4>Schedules </h4>
                    Click a day on the calendar to create a schedule. You can create multiple schedules for the same day with varying preferences such as location, price, and time.

                    <i> If multiple schedules are created on the same day, the schedule closing period must be the same.</i>
                    <br/><br/>
                    <h4>Bids</h4>
                    After you create a schedule, businesses can submit bids<b>*</b> for your services.
                      <br/>&nbsp;&nbsp;&nbsp;<span className="text-info title">• Bids</span> are highlighted in blue on the Calendar by checking the <span className="text-info title">Bid</span> box.
                    <br/><br/><b>*</b>If you have <b>Approve Bids</b> enabled in your schedule, <u className="text-purple">you will receive bid requests first</u>.
                    <br/>&nbsp;&nbsp;&nbsp;• When you receive a bid request, you can <span className= "text-info title">approve</span> or <span className="text-danger title">reject</span> the bid. 
                    <br/>&nbsp;&nbsp;&nbsp;• We recommend reading reviews and communicating with the bidder to ensure your expectations align.
                    <br/>&nbsp;&nbsp;&nbsp;• New bid requests can be found on your <Link to={{pathname: `/dash/celeb-dashboard`,}}> Dashboard</Link>, by clicking the 'Bid Requests' button.  
                    <br/>&nbsp;&nbsp;&nbsp;• You can also find bid requests <span className="text-purple title"> highlighted in purple</span> on the calendar by checking the <span className="text-purple title">Request</span> box.
                    <br/><br/>
                     <h4>Bookings</h4>
                     Once your schedule closes, LiveNyte will optimize your active bids by booking those that maximize revenue and minimize driving distance across all your schedules for that day.
                     You can also get booked for events before your schedule closes by using <i>Book early</i> or if a business uses <i>Book Now</i>. 
                     <br/>&nbsp;&nbsp;&nbsp;• Find your upcoming bookings on the <Link to={{pathname: `/dash/celeb-dashboard`,}}> Dashboard</Link> by clicking the 'Upcoming Bookings' button.  
                     <br/>&nbsp;&nbsp;&nbsp;• Upcoming bookings are also <span className="text-success primary"> highlighted in pink</span> on the calendar by checking the <span className="text-primary title">Booked</span> box.
                      <br/><br/>
                     <h4>Payouts</h4>
                     You can find your payouts on the <Link to={{pathname: `/dash/payments`,}}> Payments</Link> page.  
                    <br/><br/>
                  </CardBody>
              </Col>
          </Row>
        </ModalBody> 
    );
  }
}

function mapStateToProps(state) {
  const {  feesData } = state;
  const { fees, fetchingFees } = feesData;
  return {
    fees,fetchingFees
  };
}

const connectedSchedulingHelpModal = connect(mapStateToProps)(SchedulingHelpModal);
export { connectedSchedulingHelpModal as SchedulingHelpModal }; 