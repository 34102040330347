import React from "react";
import { Link } from 'react-router-dom';
import {
  CardBody,
  Row,
  Col,
  ModalBody,
} from "reactstrap";
// import { history } from '_helpers';

// import Datetime from "react-datetime";
import { connect } from 'react-redux';
// import { Redirect } from "react-router-dom";
// import * as moment from 'moment';
// import {
//   withScriptjs,
//   withGoogleMap,
//   GoogleMap,
//   Marker
// } from "react-google-maps";

          

class SchedulingHelpersHelpModal extends React.Component {
  constructor(props) {
    super(props);
    console.log("CreateUpdateLocation constructor()", props)
    this.state = {
    };
  }

  

  componentDidMount() {

  }



  render() {
    const {fees } = this.props   
    console.log("fees", fees)
    // let remit = num * ticket.price
    // let lvFee = remit * (liveNyteTicketPercentage/100) +
    //             num   * liveNyteTicketFlatFee
    // let stripeFee = (remit + lvFee + stripeFlatFee) / (1 - (stripePercentage/100)) - remit - lvFee
    // console.log("num", num, "remit", remit, "lvFee", lvFee, "stripeFee", stripeFee)
    console.log("SchedulingHelpersHelpModal: render", this.state)

    return (
      <ModalBody>
          <Row>
            <Col xs={12}>
                  <CardBody className="text-left">
                    <h4>Locations</h4>
                    Select an address and set a radius you're comfortable driving within for events. Create locations for areas where you frequently work. 
                    <br/><br/><b><u>To create schedules, you must have at least one location created.  </u></b>
                    <br/><br/>
                    <h4>Templates</h4>
                    Templates are pre-loaded data that you can quickly add to schedules to speed up the creation process. Try creating a template!
                    <br/><br/>
                    <h4>Schedules</h4>
                    Go to <Link to={{pathname: `/dash/scheduling`,}}> Scheduling </Link> (accessible on the left sidebar). Click a day on the calendar, and create a schedule. 
                    Once you've created a schedule, businesses can submit booking offers for your services.
                    <br/><br/>
                    You can create multiple schedules in a day, varying most scheduling preferences such as location, price, time, etc.<br/><br/><i>If multiple schedules are created on the same day, the schedule closing period must be the same.</i>
                    <br/><br/>
                  </CardBody>
              </Col>
          </Row>
        </ModalBody> 
    );
  }
}

function mapStateToProps(state) {
  const {  feesData } = state;
  const { fees, fetchingFees } = feesData;
  return {
    fees,fetchingFees
  };
}

const connectedSchedulingHelpersHelpModal = connect(mapStateToProps)(SchedulingHelpersHelpModal);
export { connectedSchedulingHelpersHelpModal as SchedulingHelpersHelpModal }; 