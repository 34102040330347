import React from "react";
import {
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col,
    CardImg,
    UncontrolledTooltip
  } from "reactstrap";
  // react plugin used to 
// import { history } from '_helpers';
import {  dispTimePeriod, subEventsNeedAttention} from '_helpers';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as moment from 'moment';

import noImage from "assets/img/img-livenyte/LiveNyteNoImage.jpg";


class BusinessEventCard  extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };

  }

  componentDidMount() {

  }

  render() {
    const now = moment() 
    const event = this.props.event
    const new_messages = this.props.new_messages
    const sbTraitStatus = this.props.sbTraitStatus
    const eventsNeedingReviews = this.props.eventsNeedingReviews
    return (
        <Link to={{pathname: `/dash/event/${event.event_id}`,}} > 
            <Card >
            <CardImg
            alt="..."
            src={event.image_url}
            onError={(e)=>{e.target.onerror = null; e.target.src=noImage}}
            top
            />
            <CardBody>
                <CardTitle className="h2 mb-0">
                {event.event_name}
                </CardTitle>
                <h6 className="surtitle pt-2">
                    {dispTimePeriod(event.ev_start_time, event.ev_end_time, event.time_zone, true)}                                              
                </h6>
            </CardBody>
            <CardFooter>
                <Row >
                { (new_messages && new_messages.filter(msg => msg.event_id === event.event_id).length > 0) && 
                <Col className="col-auto pr-0">
                    <div 
                    id={`event_${event.event_id}_message`}
                    className="icon icon-sm icon-shape bg-gold text-white rounded-circle shadow">
                    <i className="ni ni-chat-round" />
                        <UncontrolledTooltip
                        delay={0}
                        target={`event_${event.event_id}_message`}
                        >
                        New Message
                        </UncontrolledTooltip>  
                    </div>
                </Col>}
                {subEventsNeedAttention(event, sbTraitStatus) && moment(event.ev_end_time).isAfter(now) && 
                <Col className="col-auto pr-0">
                    <div 
                    id={`event_${event.event_id}_needsAttention`}
                    className="icon icon-sm icon-shape bg-gradient-cyan text-white rounded-circle shadow">
                    <i className="fas fa-bolt"></i>
                    <UncontrolledTooltip
                        delay={0}
                        target={`event_${event.event_id}_needsAttention`}
                        >
                        Needs Attention
                    </UncontrolledTooltip>  
                    </div>
                </Col>} 
                { moment(event.ev_end_time).isAfter(now) && 
                <Col className="col-auto pr-0">
                    <div 
                    id={`event_${event.event_id}_upcoming`}
                    className="icon icon-sm icon-shape bg-gradient-livenyte text-white rounded-circle shadow">
                    <i className="fas fa-star" />
                    <UncontrolledTooltip
                        delay={0}
                        target={`event_${event.event_id}_upcoming`}
                        >
                        Upcoming Event
                        </UncontrolledTooltip>  
                    </div>
                </Col>}
                { moment(event.ev_end_time).isBefore(now) && 
                <Col className="col-auto pr-0">
                    <div 
                    id={`event_${event.event_id}_past`}
                    className="icon icon-sm icon-shape bg-gradient-gray text-white rounded-circle shadow">
                    <i className="fas fa-star" />
                    <UncontrolledTooltip
                        delay={0}
                        target={`event_${event.event_id}_past`}
                        >
                        Past Event
                    </UncontrolledTooltip>  
                    </div>
                </Col>}
                {eventsNeedingReviews.filter( e => e.event_id === event.event_id).length > 0 && 
                <Col className="col-auto pr-0">
                    <div 
                    id={`event_${event.event_id}_needs_review`}
                    className="icon icon-sm icon-shape bg-gradient-pumpkin text-white rounded-circle shadow">
                    <i className="fas fa-pen" />
                    <UncontrolledTooltip
                        delay={0}
                        target={`event_${event.event_id}_needs_review`}
                        >
                        Reviews Available
                    </UncontrolledTooltip>  
                    </div>
                </Col>}
                </Row>
            </CardFooter>
            </Card>
        </Link>
    );
  }
}

function mapStateToProps(state) {
  
    console.log("BusinessEventCard: mapStateToProps");
    return {

    };
  }


const connectedBusinessEventCard = connect(mapStateToProps)(BusinessEventCard);
export { connectedBusinessEventCard as BusinessEventCard   }; 