import { messageConstants, eventConstants } from '_constants';

const initialState = {};

function preprocessAllEventMessages(data) {
    let eventMessagesMap = {}; // Key: event_id, Value: Array of messages
  
    for (const member_id in data) {
      const memberData = data[member_id];
  
      if (!Array.isArray(memberData) || memberData.length < 4) continue; // Ensure valid structure
  
      const eventDict = memberData[3]; // Dictionary at index 3
      if (!eventDict || typeof eventDict !== "object") continue;
  
      for (const eventId in eventDict) {
        if (!Array.isArray(eventDict[eventId])) continue;
  
        // Initialize the event entry as an array of arrays if it doesn’t exist
        if (!eventMessagesMap[eventId]) {
          eventMessagesMap[eventId] = [];
        }
  
        // Keep message groups as separate arrays
        eventMessagesMap[eventId].push(...eventDict[eventId]);
      }
    }
  
    return eventMessagesMap;
  }

export function messagesData(state = initialState, action) {
    switch(action.type) {
        // Get Messages
        case messageConstants.GET_MESSAGES_REQUEST:
            return {
                ...state,
                fetchingMessages: true,
            };
        case messageConstants.GET_MESSAGES_SUCCESS:
            return {
                ...state,
                fetchingMessages: false,
                messages: action.data.messages,
            };
        case messageConstants.GET_MESSAGES_FAILURE:
            return {
                ...state,
                fetchingMessages: false,
            };

        // Business Events Query also returns (unread) message
        case eventConstants.GET_BUS_EVENTS_SUCCESS:
            return {
                ...state,
                new_messages: action.new_messages,
            };
        case eventConstants.GET_BUS_EVENT_SUCCESS:
            return {
                ...state,
                new_event_messages: action.new_messages,
            };

        case messageConstants.GET_ALL_MESSAGES_REQUEST:
            return {
                ...state,
                fetchingAllMessages: true,
            };
        case messageConstants.GET_ALL_MESSAGES_SUCCESS:
            return {
                ...state,
                fetchingAllMessages: false,
                allMessages: preprocessAllEventMessages(action.data.messages),
            };
        case messageConstants.GET_ALL_MESSAGES_FAILURE:
            return {
                ...state,
                fetchingAllMessages: false,
            };
        // Set Message
        case messageConstants.SET_MESSAGE_REQUEST:
            return {
                ...state,
                setMessageRequest: true,
            };
        case messageConstants.SET_MESSAGE_SUCCESS:
            return {
                ...state,
                setMessageRequest: false,
            };
        case messageConstants.SET_MESSAGE_FAILURE:
            return {
                ...state,
                setMessageRequest: false,
            };
        case messageConstants.MARK_MESSAGE_AS_READ_REQUEST:
            return {
                ...state,
                markMessageAsReadRequest: true,
            };
        case messageConstants.MARK_MESSAGE_AS_READ_SUCCESS:
            return {
                ...state,
                markMessageAsReadRequest: false,
            };
        case messageConstants.MARK_MESSAGE_AS_READ_FAILURE:
            return {
                ...state,
                markMessageAsReadRequest: false,
            };
        default:
            return state
    }
}