import { messageConstants } from '_constants';
import { userService } from '_services';
import { alertActions, userActions } from '_actions';
//import { history } from '_helpers';

export const messageActions = {
    getMessages,
    getAllMessages,
    setMessage,
    markMessagesAsRead,
};

function getAllMessages(handler=null) {
    const params = {
        request: 'get_messages',
      };
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Got Messages");
                    console.log(data)
                    dispatch(success(data));
                    if (handler) {
                        handler(success, data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getMessages: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };
    
    function request()       { return { type: messageConstants.GET_ALL_MESSAGES_REQUEST} }
    function success(data)   { return { type: messageConstants.GET_ALL_MESSAGES_SUCCESS, data } }
    function failure(error)  { return { type: messageConstants.GET_ALL_MESSAGES_FAILURE, error } }
}

function getMessages(eventId = null, handler=null) {
    const params = {
        request: 'get_messages',
      };
      if (eventId !== null) {
          params['event_id'] = eventId
      }
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Got Messages");
                    console.log(data)
                    dispatch(success(data));
                    if (handler) {
                        handler(success, data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getMessages: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };
    
    function request()       { return { type: messageConstants.GET_MESSAGES_REQUEST} }
    function success(data)   { return { type: messageConstants.GET_MESSAGES_SUCCESS, data } }
    function failure(error)  { return { type: messageConstants.GET_MESSAGES_FAILURE, error } }
}

function setMessage(friendId, eventId, message, handler=null) {
    const params = {
        request: 'set_message',
        friend_id: friendId,
        event_id: eventId,
        message: message
      };
   
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Set Message");
                    console.log(data)
                    dispatch(success(data));
                    if (handler) {
                        dispatch(getMessages(eventId, handler))
                    } else {
                        dispatch(getMessages(eventId))
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (handler) {
                        handler(false, msg)
                    }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("setMessage: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }

        )
    };
    
    function request()       { return { type: messageConstants.SET_MESSAGE_REQUEST} }
    function success(data)   { return { type: messageConstants.SET_MESSAGE_SUCCESS, data } }
    function failure(error)  { return { type: messageConstants.SET_MESSAGE_FAILURE, error } }
}

function markMessagesAsRead(messages, eventId) {
    const params = {
        request: 'mark_messages_as_read',
        message_ids: messages,
      };

    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Mark Messages as Read success");
                    console.log(data)
                    dispatch(success(data));
                    getMessages(eventId);
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("markMessagesAsRead: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };
    
    function request()       { return { type: messageConstants.MARK_MESSAGE_AS_READ_REQUEST} }
    function success(data)   { return { type: messageConstants.MARK_MESSAGE_AS_READ_SUCCESS, data } }
    function failure(error)  { return { type: messageConstants.MARK_MESSAGE_AS_READ_FAILURE, error } }
}

