import React from "react";
// import { Link } from 'react-router-dom';
import {
  CardBody,
  Row,
  Col,
  ModalBody,
} from "reactstrap";
// import { history } from '_helpers';

// import Datetime from "react-datetime";
import { connect } from 'react-redux';
// import { Redirect } from "react-router-dom";
// import * as moment from 'moment';
// import {
//   withScriptjs,
//   withGoogleMap,
//   GoogleMap,
//   Marker
// } from "react-google-maps";

          

class ReplacementPlanHelpModal extends React.Component {
  constructor(props) {
    super(props);
    console.log("CreateUpdateLocation constructor()", props)
    this.state = {
    };
  }

  

  componentDidMount() {

  }


  render() {
    const {fees } = this.props   
    console.log("fees", fees)
    // let remit = num * ticket.price
    // let lvFee = remit * (liveNyteTicketPercentage/100) +
    //             num   * liveNyteTicketFlatFee
    // let stripeFee = (remit + lvFee + stripeFlatFee) / (1 - (stripePercentage/100)) - remit - lvFee
    // console.log("num", num, "remit", remit, "lvFee", lvFee, "stripeFee", stripeFee)
    console.log("SchedulingHelpersHelpModal: render", this.state)

    return (
      <ModalBody>
          <Row>
            <Col xs={12}>
                  <CardBody className="text-left pt-2">
                    <h4>Replacement Plans can be updated</h4>
                    To update the price of a replacement plan, navigate to the active plan it is replacing, and then update both the price and the replacement date.
                    <br/><br/>
                    <h4>Replacement Plans cannot be deleted</h4>
                    Emails are sent to your subscribers when a replacement plan is created, notifying them of the change to their plan. 
                    Once a replacement plan is created, it cannot be deleted. 
                    However, after a replacement plan takes over an active plan, it can be replaced again.
                    <br/><br/>
                  </CardBody>
              </Col>
          </Row>
        </ModalBody> 
    );
  }
}

function mapStateToProps(state) {
  const {  feesData } = state;
  const { fees, fetchingFees } = feesData;
  return {
    fees,fetchingFees
  };
}

const connectedReplacementPlanHelpModal = connect(mapStateToProps)(ReplacementPlanHelpModal);
export { connectedReplacementPlanHelpModal as ReplacementPlanHelpModal }; 