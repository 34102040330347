import React from "react";
import {
  CardBody,
  Row,
  Col,
  ModalBody,
} from "reactstrap";
// import { history } from '_helpers';

// import Datetime from "react-datetime";
import { connect } from 'react-redux';
// import { Redirect } from "react-router-dom";
// import * as moment from 'moment';
// import {
//   withScriptjs,
//   withGoogleMap,
//   GoogleMap,
//   Marker
// } from "react-google-maps";

          

class SubeventHelpModal extends React.Component {
  constructor(props) {
    super(props);
    console.log("CreateUpdateLocation constructor()", props)
    this.state = {
    };

  }

  

  componentDidMount() {
  }

  render() {
    const {fees } = this.props   
    const {fetchingFees} = fees
    // let remit = num * ticket.price
    // let lvFee = remit * (liveNyteTicketPercentage/100) +
    //             num   * liveNyteTicketFlatFee
    // let stripeFee = (remit + lvFee + stripeFlatFee) / (1 - (stripePercentage/100)) - remit - lvFee
    // console.log("num", num, "remit", remit, "lvFee", lvFee, "stripeFee", stripeFee)
    console.log("SubeventHelpModal: render", this.state)


    if (fetchingFees === true) {
      return(
        <div>Loading</div>
      )
    }
    return (
      <ModalBody>
 
          <Row>
            <Col xs={12}>
                  <CardBody className="text-left pt-0">
                    <h4>General</h4>
                    A time block is a period during your event when you want to book talent. 
                    <br/><br/>
                    Choose the start and end times for your time block, specify the types of talent you need, and indicate how many you'd like to book (e.g., 2 comedians, 3 musicians, 1 actor, 4 models, etc.).

                    <br/><br/>
                    Place bids on the talent you're interested in booking. LiveNyte ensures that talent is never overbooked, so feel free to place as many bids as you'd like. You'll only book up to the number of talent you've specified.
                    <br/><br/>
                    If you want to book talent immediately, you can select the <b>'Book Now'</b> option after the talent approves your bid.
                    </CardBody>
              </Col>
          </Row>
        </ModalBody> 
    );
  }
}

function mapStateToProps(state) {
  const {  feesData } = state;
  const { fees, fetchingFees } = feesData;
  return {
    fees,fetchingFees
  };
}

const connectedSubeventHelpModal = connect(mapStateToProps)(SubeventHelpModal);
export { connectedSubeventHelpModal as SubeventHelpModal }; 